<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <BrandUserForm
      v-if="!isLoading"
      :brandUser="brandUser"
      v-on:addOrUpdateBrandUser="addOrUpdateBrandUser()"
      :submitName="$t('add')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "./../../utils/create-toast-mixin";
import PreLoader from "./../../components/general/PreLoader.vue";
import { STATUS } from "./../../utils/constants";
import BrandUserForm from "./../../components/brandUsers/BrandUserForm.vue";
import BrandUser from "./../../models/brandUsers/BrandUser";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    BrandUserForm,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      brandUser: new BrandUser(),
    };
  },
  methods: {
    async addOrUpdateBrandUser() {
      this.isLoading = true;
      try {
        const response = await this.brandUser.addOrUpdateBrandUser(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.brandUser.setInitialValue();
          this.showMsg(response.data.msg, true);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {},
};
</script>
